import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  BehaviorSubject,
  Observable,
  lastValueFrom,
  map,
  shareReplay,
  tap,
} from 'rxjs';
import { ConfirmDialogComponent } from '../components/common/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  loading$: Observable<boolean>;
  isMobile$: Observable<boolean>;

  private loading = new BehaviorSubject<boolean>(false);
  private loadingCounter = 0;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
  ) {
    this.loading$ = this.loading;
    this.isMobile$ = breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .pipe(
        map((result) => result.matches),
        tap((isMobile) => {
          document.body.classList.toggle('plt-mobile', isMobile);
          const fwWidget = document.getElementById('freshworks-container');
          if (fwWidget) {
            fwWidget.style.display = isMobile ? 'none' : 'block';
          }
        }),
        shareReplay(1),
      );
  }

  toggleLoading(state: boolean) {
    this.loadingCounter += state ? 1 : -1;
    this.loading.next(this.loadingCounter > 0);
  }

  showSuccessMessage(): void {
    this.showMessage('Data successfully saved');
  }

  showErrorMessage(message?: string) {
    this.showMessage(
      message ?? 'Oops, something went wrong, please try again.',
      3000,
    );
  }

  showMessage(message: string, duration = 2000) {
    this.snackBar.open(message, undefined, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration,
    });
  }

  confirm(): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    return lastValueFrom(dialogRef.afterClosed());
  }
}
